export const PHOTO_DATA = [
  {
    title: 'egg spinach',
    description: 'PLACEHOLDER',
    imageSrc: 'photo-food-eggspinach.webp',
  },
  {
    title: 'sesame chicken',
    description: 'PLACEHOLDER',
    imageSrc: 'photo-food-sesamechicken.webp',
  },
  {
    title: 'banana cream pie',
    description: 'PLACEHOLDER',
    imageSrc: 'photo-food-bananacreampie.webp',
  },
  {
    title: 'yoga savage',
    description: 'PLACEHOLDER',
    imageSrc: 'photo-food-yogasavage.webp',
  },
  {
    title: 'talk',
    description: 'PLACEHOLDER',
    imageSrc: 'photo-human-talk.webp',
  },
  {
    title: 'subway',
    description: 'PLACEHOLDER',
    imageSrc: 'photo-human-subway.webp',
  },

  {
    title: 'dress',
    description: 'PLACEHOLDER',
    imageSrc: 'photo-human-dress.webp',
  },
  {
    title: 'father and son',
    description: 'PLACEHOLDER',
    imageSrc: 'photo-human-father-and-son.webp',
  },
  {
    title: 'dc',
    description: 'PLACEHOLDER',
    imageSrc: 'photo-city-dc.webp',
  },
  {
    title: 'nyc',
    description: 'PLACEHOLDER',
    imageSrc: 'photo-city-nyc.webp',
  },
];
