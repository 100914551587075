export const DRAWING_DATA = [
  {
    title: 'boat',
    description: 'PLACEHOLDER',
    imageSrc: 'drawing-watercolor-boat.webp',
  },
  {
    title: 'dream',
    description: 'PLACEHOLDER',
    imageSrc: 'drawing-watercolor-dream.webp',
  },
  {
    title: 'dancer',
    description: 'PLACEHOLDER',
    imageSrc: 'drawing-charcoal-dancer.webp',
  },
  {
    title: 'flow',
    description: 'PLACEHOLDER',
    imageSrc: 'drawing-charcoal-flow.webp',
  },
  {
    title: 'home',
    description: 'PLACEHOLDER',
    imageSrc: 'drawing-oil-paint-home.webp',
  },
];
