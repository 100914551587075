export const APP_DATA = [
  {
    type: 'app',
    title: 'Snap App',
    slug: 'snap-app',
    technologyList: ['React', 'Node', 'Express', 'MongoDB'],
    description:
      'Snap App focuses on creating a photo sharing platform for people who love architecture.',
    imageSrc: 'app-snap-cover.webp',
    demoUrl: 'https://howiework.github.io/snap-app/',
    gitHubUrl: 'https://github.com/HowieWork/snap-app',
    embedId: '9CF7wCwRC5c',
    viewProject: '/portfolio/app/snap-app',
  },
  {
    type: 'app',
    title: 'Lunch Break',
    slug: 'lunch-break-app',
    technologyList: ['Next', 'React', 'MongoDB', 'Vercel'],
    description:
      'A blog site focus on work-life balance. Its content is around three topics: design, psychology and community.',
    imageSrc: 'app-lunch-break-cover.webp',
    demoUrl: 'https://lunch-break.vercel.app/',
    gitHubUrl: 'https://github.com/HowieWork/lunch-break/',
    embedId: 'jIDyerwpAXw',
    viewProject: '/portfolio/app/lunch-break-app',
  },
  // TODO BRING MODAL UPDATED PROJECT BACK
  // {
  //   type: 'app',
  //   title: 'Modal',
  //   slug: 'modal',
  //   technologyList: ['HTML', 'CSS', 'JavaScript'],
  //   description:
  //     'Modal is a simple app introducing philosophy about Death and Living.',
  //   imageSrc: 'app-modal-cover.webp',
  //   demoUrl: 'https://howiework.github.io/modal/',
  //   gitHubUrl: 'https://github.com/HowieWork/modal',
  // },
];

export const TUTORIAL_APP_DATA = [
  {
    type: 'app',
    title: 'Natours',
    slug: 'natours',
    technologyList: ['Node', 'Express', 'MongoDB'],
    description:
      'A web app for helping people choose where to travel in North America',
    imageSrc: 'app-natours-cover.webp',
    demoUrl: 'https://natours-wv6y.onrender.com',
    gitHubUrl: 'https://github.com/HowieWork/natours',
  },
  {
    type: 'app',
    title: 'Forkify',
    slug: 'forkify',
    technologyList: ['HTML', 'CSS', 'JavaScript'],
    description:
      'Search over 1,000,000 recipes. Find your favorite recipe and bookmark it.',
    imageSrc: 'app-forkify-cover.webp',
    demoUrl: 'https://forkify-howie.netlify.app/',
    gitHubUrl: 'https://github.com/HowieWork/forkify',
  },
  {
    type: 'app',
    title: 'Mapty',
    slug: 'mapty',
    technologyList: ['HTML', 'CSS', 'JavaScript'],
    description:
      'An app maps your workouts. Built with OOP, Geolocation API, Web Storage API.',
    imageSrc: 'app-mapty-cover.webp',
    demoUrl: 'https://howiework.github.io/mapty/',
    gitHubUrl: 'https://github.com/HowieWork/mapty',
  },
  {
    type: 'app',
    title: 'Bankist',
    slug: 'bankist',
    technologyList: ['HTML', 'CSS', 'JavaScript'],
    description:
      'A simple web tool for managing your bank account. Built with HTML, CSS and JavaScript.',
    imageSrc: 'app-bankist-cover.webp',
    demoUrl: 'https://howiework.github.io/bankist/',
    gitHubUrl: 'https://github.com/HowieWork/bankist',
  },
  {
    type: 'app',
    title: 'Bankist Homepage',
    slug: 'bankist-homepage',
    technologyList: ['HTML', 'CSS', 'JavaScript'],
    description: 'A homepage design for Bankist App',
    imageSrc: 'app-bankist-homepage-cover.webp',
    demoUrl: 'https://howiework.github.io/bankist-homepage/',
    gitHubUrl: 'https://github.com/HowieWork/bankist-homepage',
  },
  {
    type: 'app',
    title: 'Guess My Number',
    slug: 'guess-my-number',
    technologyList: ['HTML', 'CSS', 'JavaScript'],
    description: 'A guess number game. Built with DOM, JavaScript',
    imageSrc: 'app-guess-my-number-cover.webp',
    demoUrl: 'https://howiework.github.io/guess-my-number/',
    gitHubUrl: 'https://github.com/HowieWork/guess-my-number',
  },
  {
    type: 'app',
    title: 'Pig Game',
    slug: 'pig-game',
    technologyList: ['HTML', 'CSS', 'JavaScript'],
    description: 'A dice game. Built with DOM, JavaScript',
    imageSrc: 'app-pig-game-cover.webp',
    demoUrl: 'https://howiework.github.io/pig-game/',
    gitHubUrl: 'https://github.com/HowieWork/pig-game',
  },
  {
    type: 'app',
    title: 'Monster Rolodex',
    slug: 'monster-rolodex',
    technologyList: ['React'],
    description: 'A list of Monsters. Built with React',
    imageSrc: 'app-monsters-rolodex-cover.webp',
    demoUrl: 'https://howiework.github.io/monsters-rolodex/',
    gitHubUrl: 'https://github.com/HowieWork/monsters-rolodex',
  },
];
